export default {
  title: "发起人",
  title_desc:
    "我们的发起人为<b>信银资本、清科集团、清科资本、倪正东先生、李竹先生</b>及<b>刘伟杰先生</b>。清科集团及清科资本由倪先生最终控制。倪先生及李先生为本公司执行董事及高级管理层。刘先生为本公司非执行董事。截至本文件日期，信银资本、清科集团、清科资本、倪先生、李先生及刘先生分别间接持有我们已发行B类股份的35%、15%、15%、10%、20%及5%。",
  top_nav: [
    {
      name: "信银资本",
      id: "CNCB",
      desc: `信银资本为信银投资的全资附属公司，而信银投资为中信银行的附属公司。<br/><br/>

    信银资本获证监会许可从事证券及期货条例项下一系列受规管活动，包括第1 类（证券交
    易）、第4类（就证券提供意见）、第6类（就机构融资提供意见）及第9类（提供资产管理）受规管活动。信银资本主要从事金融及投资银行服务，包括香港的私募股权融资、基金投资及资产管理。凭借香港市场作为其投资平台，并获中信银行广泛的投资网络及资源的支持下，信银资本为广泛的客户群服务，包括民营企业、地方及国家金融机构以及国有企业，并为中信银行提供的综合金融服务的海外延伸。<br/><br/>
    
    截至2019年及2020年12月31日，信银资本管理资产超过10亿美元。截至2021年12 月31日，信银资本管理超过10只股权基金及固定收入基金，并就该等基金提供意见，涵盖电子商务、医疗保健、物流及生物科技等行业。透过多个投资方式，包括一级及二级市场的股权投资、结构性融资、债券投资、并购融资及股权质押融资，信银资本向企业提供募资机会。信银资本获久期财经认可为2021年度“杰出承销商 －SBLC类”。截至2021年12月31日，信银资本管理资产超过15亿美元。<br/><br/>
    
    信银资本由信银投资全资拥有，后者提供综合金融服务及产品，包括企业融资、证券销售
    及贸易、资产管理及投资。信银投资亦提供研究服务，涵盖中国及全球宏观经济、国际金融市场及主要行业。信银投资在投资银行及商业银行、境内业务及境外业务、资本市场及货币市场方面已建立协同效益，并寻求协助其客户实现价值，扩大规模及盈利以及创造价值。信银资本凭借卓越的服务获得了多项行业奖项及荣誉，包括《投中网》颁发的2020年“粤港澳大湾区最佳私募股权投资机构TOP30”及2021年度“中国最佳私募股权投资机构TOP100”。<br/><br/>
    
    信银投资为中信银行的附属公司。中信银行于1987年创立，是中国改革开放时期最初成立
    的商业银行之一，亦是中国第一批参兴国内及国外金融市场的商业银行。中信银行在联交所（股份代号：0998）及上海证券交易所（股份代号：601998）双重上市，截至2021年12月31日，通过其遍布中国153 个城市的1,415 个分支机构提供全面的金融服务。`,
    },
    {
      name: "清科集团",
      id: "Zero2IPOGroup",
      desc: `清科集团为中国领先的投资管理公司，由倪先生控制。截至最后实际可行日期，倪先生实益拥有清科集团约54.93%的股权。清科集团于创业投资、基金管理及行业投资领域拥有丰富的经验。于2019年、2020年及2021年，清科集团已管理平均总市值至少80亿港元的资产。截至2022年5月31日，透过管理逾40个人民币私募股权基金，清科集团管理资产超过人民币100亿元。<br/><br/>

    凭借良好声誉、专业见解及经验丰富的投资团队，清科集团投资横跨多个行业、不同成长阶段的公司。`,
    },
    {
      name: "清科资本",
      id: "Zero2IPOCapital",
      desc: `清科资本自 2021 年 11 月 3 日起获证监会许可从事第 6 类（就机构融资提供意见）受规管活动。其中，清科资本获准就申请于联交所上市的证券担任保荐人。清科资本是清科创业控股的间接全资附属公司。清科创业控股由倪先生最终控制，截至最后可行日期，倪先生控制清科创业控股约47.42%的表决权。<br/><br/>

    清科创业控股是股权投资行业的综合服务平台，为股权投资行业参与者提供数据、营销、投行及培训服务。清科创业控股为股权投资行业的所有参与者及利益相关者（包括投资者、企业家、成长型企业及政府机构）提供广泛的线上或线下服务。截至2021年12月31日，清科创业控股的专有PEdata Database（私募通）共有超过275,800名注册用户，而其线上信息平台在其移动应用程序、网站及主要第三方平台上累积超过2.3百万名用户。 2021年，清科创业控股举办了四场清科品牌线下活动，参与人数超过2,600人，2021年举办了12场线下清科品牌定制活动，参与人数约3,000人，为参与人士提供了面对面的互动和社交机会。凭借其于中国股权投资行业的领先地位，清科创业控股自2020年于联交所上市（股份代号：1945）以来已将其业务拓展至香港，即亚洲的金融中心，也是全球最具吸引力的集资平台之一。清科创业控股通过其间接全资附属公司间接持有证监会根据证券及期货条例发出的第1类（证券交易）、第2类（期货合约交易）、第4类（就证券提供意见）、第6类（就机构融资提供意见）及第9类（提供资产管理）受规管许可。因此，清科创业控股能够在香港提供金融服务，包括但不限于证券经纪、证券包销及配售、企业财务咨询、首次公开募股的保荐以及资产及财富管理服务。透过清科创业控股广大的业务网络、广泛的数据资源及在中国股权投资行业的领先地位，清科资本作为清科创业控股在香港的投行平台，积极参与首次公开募股及并购交易，拥有为广大客户提供独立财务顾问服务的良好往绩记录。`,
    },
    {
      name: "倪正东先生",
      id: "Zhengdong",
      desc: `倪先生在股权投资行业拥有逾20年经验。倪先生为清科创业控股创办人兼首席执行官、执行董事兼董事长，主要负责业务、战略及企业发展的整体管理。彼于2001年开办清科集团及清科创业控股的业务，自2005年清科集团成立以来，历任清科集团执行董事、董事长。倪先生亦担任清科资本的董事。<br/><br/>

    倪先生自2022年6月起担任联交所上市公司快狗打车控股有限公司（股份代号：2246）独立非执行董事。倪先生于2017年9月至2022年5月担任深圳证券交易所上市公司拓维信息系统股份有限公司（股份代号：002261）独立董事，并于2022 年5 月起担任董事，2021年1 月至2021 年12月担任联交所上市公司金蝶国际软件集团有限公司（股份代号：0268）独立非执行董事，2015年3月至2019年1月担任前纳斯达克股票交易所上市公司iKang Healthcare Group, Inc.（股份代号：KANG）独立董事，2011年6月至2017年6月担任深圳证券交易所上市公司北京三夫户外用品股份有限公司（股份代号：002780）董事。`,
    },
    {
      name: "李竹先生",
      id: "LIZHU",
      desc: `李先生拥有约30 年的多家公司高管经验、逾20 年的咨询顾问经验及逾10年的中国私募股权投资经验。李先生自2013年3月起成为英诺天使基金的创始合伙人。李先生创立英诺天使基金之前，于2012年创立厚德创新谷(Houde Innovation Valley)，并随后于2015年6月至2016年10月担任 北京厚德文华投资咨询有限公司(Beijing Houde Wenhua Investment Consulting Co., Ltd.)董事长。在创立厚德创新谷(Houde Innovation Valley)之前，李先生于1997年6月至2000年5月间担任同方软件与系统集成公司*(Tsinghua Tongfang Software and System Integration Company)总经理。 2005年，李先生创立了国内直播及视频平台北京悠视互动科技有限公司。<br/><br/>

    凭借彼于投资领域多年的成就，李先生于2014年获颁中关村创业投资和股权投资基金协会
    “中关村天使投资领军人物”及于2019年获颁中央电视台“最受创业者欢迎的中国十大投资人”。`,
    },
    {
      name: "刘伟杰先生",
      id: "LAUWAIKIT",
      desc: `刘先生于投资、并购及企业管理方面拥有逾20 年经验。彼自2014 年12 月起一直为水木基金的合伙人，该公司为一家专注于新经济领域的医疗、科技及其他行业成长阶段的机遇的私募股权公司。在加入水木基金之前，刘先生于2002 年1 月联同他人创立戈壁创投，戈壁创投为一家风险投资机构，专注于投资中国及东南亚早期科技公司。戈壁创投的投资者包括IBM、NTT Docomo、麦格劳希尔及Sierra Ventures。彼担任戈壁创投的高级管理合伙人，直至2014年12月止。自2000年8月至2001年3月，彼担任Asia2B.com的首席财务官。自1998年至2000年，彼任职于华德管理有限公司，其最后职位为执行董事。自1997年4月至1999年3月，彼担任Seapower Financial Services Group副主席兼董事。<br/><br/>

    刘先生接受过律师培训，并自香港大学获得法律学位。于1988年9月至1995年5月，彼在贝克‧麦坚时律师事务所以及苏姜律师行及苏姜叶律师行执业，专长处理跨境投资及税务。彼具有香港、加利福尼亚州、新加坡以及英格兰及威尔士的法律执业资格。`,
    },
  ],
};
