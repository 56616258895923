<template>
  <div class="header fixed-top" id="header">
    <div class="mask" v-show="toggleState"></div>
    <b-navbar toggleable="xl">
      <b-navbar-brand href="/">
        <img src="@/assets/logo.png" class="logo" alt="" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" ref="navbar_toggle">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="x" scale="1.3"></b-icon>
          <b-icon v-else icon="list" scale="1"></b-icon>
        </template>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav class="justify-content-end">
        <b-navbar-nav fill>
          <b-nav-item
            v-for="item in routerList"
            @click="jump(item)"
            :key="item.name"
            ><span :class="thisRouter == item.path ? 'blue' : ''">{{
              $t(item.meta.title)
            }}</span
            ><span v-if="thisRouter == item.path" class="bt_border"></span
          ></b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
          <div class="langTaggle">
            <span
              v-for="item in langList"
              :key="item.value"
              @click="setLang(item)"
              :class="[item.value, `${item.value === lang ? 'active' : ''}`]"
              >{{ item.name }}</span
            >
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "HeaderComponent",
  inject: ["reload"],
  data() {
    return {
      lang: "zh",
      langList: [
        {
          name: "中",
          value: "zh",
        },
        {
          name: "EN",
          value: "en",
        },
      ],
      routerList: [],
      toggleState: false,
      thisRouter: "",
    };
  },
  watch: {
    "$route.path": {
      handler: function () {
        // this.getPdfFileList();
        this.thisRouter = this.$route.path;
      },
    },
  },
  created() {
    let router = this.$router.options.routes;
    this.routerList = router;
    this.thisRouter = this.$route.fullPath;
    this.lang = sessionStorage.getItem("language") || "zh";
  },
  mounted() {
    this.$nextTick(() => {
      this.$watch(
        () => this.$refs.navbar_toggle.toggleState,
        (val) => {
          this.toggleState = val;
        }
      );
    });
  },
  methods: {
    ...mapMutations(["SET_LANG"]),
    // 语言切换
    setLang(item) {
      const { value: lang } = item;
      this.SET_LANG(lang);
      this.lang = lang;
      this.$i18n.locale = lang;
    },
    jump(item) {
      if (item.path === this.$route.fullPath) {
        this.reload();
      } else {
        this.reload();
        this.$router.push({
          path: item.path,
        });
      }
      // this.toggleState = false;
      // let btn = document.getElementsByClassName("not-collapsed")[0];
      // let btn2 = document.getElementsByClassName("collapsed")[0];
      // let box = document.getElementById("nav-collapse");
      // // className.style.display = "none";
      // // console.log(className.classList);
      // if (btn) {
      //   btn.setAttribute("aria-expanded", false);
      //   btn2?.classList.add("collapsed");
      //   btn.classList.remove("not-collapsed");
      //   box.classList.add("collapsing");
      //   box.classList.remove("show");
      //   //
      //   // setTimeout(function () {
      //   //   box.classList.remove("collapsing");
      //   // }, 300);
      // } else {
      //   btn?.setAttribute("aria-expanded", false);
      //   btn2.classList.add("collapsed");
      //   btn.classList.remove("not-collapsed");
      //   box.classList.add("collapsing");
      //   box.classList.remove("show");
      // }
    },
    showToggle() {
      // let className = document.getElementById("nav-collapse");
      // console.log(className.classList);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #fff;
  .mask {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.5);
  }
  .logo {
    widows: 98px;
    height: 24px;
  }

  :deep(.navbar) {
    background: #fff;
    position: relative;
    z-index: 100;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    .navbar-nav {
      font-size: 15px;
      .nav-item:not(.b-nav-dropdown.dropdown) {
        padding: 0 18px;
        height: 72px;
        position: relative;
        .bt_border {
          position: absolute;
          width: 28px;
          height: 2px;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          border-radius: 2px;
          //background: transparent;
          background: #3b61da;
        }
      }
      .nav-link {
        color: #252626;
        font-weight: bold;
        padding: 0;
        height: 100%;
        line-height: 72px;
        .blue {
          color: #3b61da;
        }
        .bt_border {
          background: #3b61da;
        }
        // border-bottom: 2px solid transparent;
        //&.router-link-exact-active.router-link-active {
        //  // border-bottom-color: #3b61da;
        //  color: #3b61da;
        //  .bt_border {
        //    background: #3b61da;
        //  }
        //}
      }
    }
    .langTaggle {
      font-size: 12px;
      margin-left: 30px;
      span {
        width: 45px;
        height: 28px;
        line-height: 28px;
        cursor: pointer;
        border: 1px solid rgba(213, 214, 217, 1);
        position: relative;
        &.zh {
          border-radius: 4px 0px 0px 4px;
        }
        &.en {
          border-radius: 0px 4px 4px 0px;
          margin-left: -1px;
        }
        &.active {
          border-color: #3b61da;
          z-index: 1;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .navbar {
      padding: 1rem 1rem;
      .navbar-nav {
        font-size: 16px;
        .nav-item:not(.b-nav-dropdown.dropdown) {
          height: 44px;
        }
        & .nav-link {
          line-height: 32px;
        }
      }
      .langTaggle {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}
</style>
