export default {
  title: "概览",
  desc: "一家新成立的进行特殊目的的收购公司",
  d_title:
    "我们为一家旨在进行特殊目的收购公司并购交易而新成立的特殊目的收购公司。我们拟专注于：",
  p1: "识别「新经济」领域的高增长特殊目的收购公司并购目标，包括但不限于与特殊目的收购公司并购目标经营所在司法权区的经济趋势及国家产业政策一致的创新技术、先进制造、医疗保健、生命科学、文娱产业、消费及新零售、绿色能源和气候行动行业。<br /><br />",
  p2: "凭借我们的发起人及董事集体的网络、知识与经验，我们计划与一家优质并于行业具有竞争优势且长期增长前景良好的公司进行特殊目的收购公司并购交易。",
  p3: "截至本文件日期，我们尚未选定任何潜在的特殊目的收购公司并购目标，且我们或代表我们的任何人士亦无直接或间接与任何潜在特殊目的收购公司并购目标发起有关特殊目的收购公司并购交易的任何实质性讨论。<br /><br />",
  p4: "我们将自我们的发起人及董事的自有网络取得特殊目的收购公司并购交易机会，该网络由管理人员、投资者及顾问组成。我们的发起人及董事将采用严谨且严格筛选的识别流程，期望于良机出现时凭借发起人及董事的网络、人脉及经验，进行资本结构优化、改善营运模式及进行追加收购，进而提升目标企业的价值。",
};
