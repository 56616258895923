<template>
  <div id="app">
    <Header v-if="isRouterAlive" />
    <div class="layout b_container" @click="hideCollapse">
      <router-view v-if="isRouterAlive" />
    </div>
    <Footer />
    <model-view :visible="showModel" />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ModelView from "@/components/ModelView.vue";
import { mapState } from "vuex";
export default {
  components: {
    Header,
    Footer,
    ModelView,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      show: false,
      isRouterAlive: true,
    };
  },
  computed: {
    ...mapState(["showModel"]),
  },
  created() {},
  methods: {
    /** 2022/11/7
     * @作者:BarneyJiang
     * @功能:当前页面刷新
     * @param:
     */
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    hideCollapse() {
      // let btn = document.getElementsByClassName("not-collapsed")[0];
      // let box = document.getElementById("nav-collapse");
      // // className.style.display = "none";
      // // console.log(className.classList);
      // if (btn) {
      //   btn.setAttribute("aria-expanded", false);
      //   btn.classList.add("collapsed");
      //   btn.classList.remove("not-collapsed");
      //   box.classList.add("collapsing");
      //   box.classList.remove("show");
      //
      //   setTimeout(function () {
      //     box.classList.remove("collapsing");
      //   }, 300);
      // }
      // className.classList.value
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: sans-serif, Avenir, Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #252626;
}
.layout {
  min-height: calc(100vh - 60px);
}
.hide {
  display: none;
}
.b_container {
  padding-top: 72px;
}
</style>
