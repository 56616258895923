<template>
  <div class="banner_div">
    <img class="banner_img" :src="imgLink" alt="" @load="loaded" />
    <div class="banner_content animate__animated animate__fadeInUp">
      <div class="banner_title">{{ bannerTitle }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imgSrc: {
      type: Object,
      default: () => {},
    },
    bannerTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgLink: "",
    };
  },
  mounted() {
    this.bannerChange();
    document.documentElement.scrollTop = 0;
    window.addEventListener("resize", this.bannerChange);
  },
  methods: {
    bannerChange() {
      const w = window.innerWidth,
        { pc, phone } = this.imgSrc;
      if (w <= 789) this.imgLink = phone;
      else this.imgLink = pc;
      setTimeout(() => {
        this.$emit("loading");
      }, 200);
    },
    loaded() {
      setTimeout(() => {
        this.$emit("loaded");
      }, 200);
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.bannerChange);
  },
};
</script>
<style lang="scss" scoped>
.banner_div {
  position: relative;
  .banner_img {
    width: 100%;
    display: block;
  }

  .banner_content {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .banner_title {
    font-size: 36px;
    color: #ffffff;
    font-weight: bold;
    margin-left: 23vw;
    margin-top: 7.8vw;
    text-align: left;
  }
  @media (max-width: 790px) {
    .banner_title {
      margin-left: 10vw;
      margin-top: 20vw;
      font-size: 20px;
    }
  }
}
</style>
