import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: sessionStorage.getItem("language") || "zh",
    showModel: false,
    confirmed: sessionStorage.getItem("confirmed") || "zh",
  },
  getters: {},
  mutations: {
    SET_LANG(state, val) {
      const lang = val || "zh";
      sessionStorage.setItem("language", lang);
      state.lang = lang;
    },
    SET_SHOWMODEL(state, val) {
      state.showModel = val;
    },
    SET_CONFIRMED(state, val) {
      const confirmed = val || "";
      sessionStorage.setItem("confirmed", confirmed);
      state.confirmed = confirmed;
    },
  },
  actions: {
    tellModelEvent({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.confirmed === "yes") resolve(state.confirmed);
        else {
          commit("SET_SHOWMODEL", true);
          reject(state.confirmed);
        }
      });
    },
  },
  modules: {},
});
