export default {
  text1:
    "Our objective is to generate attractive returns for our Shareholders by selecting a high-quality De-SPAC Target, negotiating favorable acquisition terms at an attractive valuation and creating the foundation to improve the operating and financial performance of the Successor Company. Although we are not limited to and may pursue De-SPAC Targets in any industry or geography, we intend to concentrate our efforts on companies operating in the new economy sector, including but not limited to innovative technology, advanced manufacturing, healthcare, life sciences, consumer and new retail, green energy and climate actions industries. These companies would leverage technology for their growth and development in their respective industry sector, which we believe will complement the expertise of our Promoters and Directors.",
  text2:
    "We expect to deploy the strong network of relationships, industry expertise and proven deal-sourcing capabilities of our Promoters and Directors to develop a robust pipeline of potential targets.",
  text3:
    "In pursuit of our business strategy, we intended to leverage our experiences in:",
  textList: [
    "sourcing investment or acquisition opportunities through Promoters’ extensive network;",
    "identifying high-quality De-SPAC Targets with long-term growth potential;",
    "evaluating and conducting company-specific analysis and due diligence reviews;",
    "negotiating, structuring and executing M&A and other capital markets transactions;",
    "investing, operating and advising on transactions and companies in new economy sector;",
    "expanding and strengthening partnerships with industry leaders and stakeholders;",
    "providing consulting advice to companies across marketing, branding, general business operations, recruiting talents and financial matters; and",
    "empower the Successor Company with financial services and intra-industrial networks.",
  ],
};
