import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocale from "./zh-EN"; // 本地英文包
import zhLocale from "./zh-CN"; // 本地中文包
// import { getCookie } from "@/utils/cookie";

Vue.use(VueI18n); // vue使用i18n模块
// 引入本地
const messages = {
  en: {
    ...enLocale,
  },
  zh: {
    ...zhLocale,
  },
};
//默认title
const language = sessionStorage.getItem("language") || "zh";
document.title = messages[language].websiteName;
// 创建国际化实例
const i18n = new VueI18n({
  locale: language, // set locale
  messages, // set locale messages
});

export default i18n;
