export default {
  title: "公司治理",
  title_desc:
    "我们团队的成员拥有丰富的投资及咨询经验，在投资横跨多个行业、不同成长阶段的公司方面有着良好的往绩记录。因此，我们的团队拥有强大的能力，能为复杂的交易提供具创造性的解决方案。此外，我们的团队拥有全面且互补的收购战略相关技能及经验。我们团队的整体经验带来竞争优势，通过积极参与识别高质量的特殊目的收购公司并购目标并与之合作，进而支持继承公司的长期增长。",
  top_nav: [
    {
      classify: "执行董事",
      id: "ED",
    },
    {
      classify: "非执行董事",
      id: "NED",
    },
    {
      classify: "独立非执行董事",
      id: "IED",
      desc: ``,
    },
    {
      classify: "高级管理层",
      id: "SMT",
    },
    {
      classify: "董事委员会名单",
      id: "BC",
    },
  ],
  detail_info: [
    {
      classify: "执行董事",
      id: "ED",
      userInfo: [
        {
          name: "• 倪正东先生",
          id: "Zhengdong",
          desc: `48岁，自本公司于2022年4月注册成立起出任董事，并于2022年6月获调任为董事会主席兼执行董事，并获委任为联席首席执行官。彼主要负责制定本公司整体战略方针及管理。倪先生为我们的发起人之一及清科资本董事。<br /><br />

          倪先生于股权投资行业拥有逾20年经验。彼于2001年开展清科集团及清科创业控股的业务，并自清科集团于2005年成立起获委任为执行董事，后获委任为董事长。倪先生亦为清科创业控股（一家联交所上市公司，股份代号：1945）董事长、执行董事兼首席执行官，负责业务、战略及企业发展的整体管理<br /><br />
          
          倪先生于1996年7月取得湖南大学工程力学学士学位，并于2000年1月取得清华大学工程力学硕士学位。彼亦于2007年1月毕业于清华大学的工商管理博士课程。`,
        },
        {
          name: "• 叶青先生",
          id: "YEQing",
          desc: `44岁，为执行董事兼联席首席执行官。彼主要负责制订本公司业务方针及管理。叶先生获信银资本提名加入董事会，并为信银资本的董事。彼作为信银资本的负责人员（定义见证券及期货条例），已获证监会发牌自2017年3月起进行第1类（证券交易）、第4类（就证券提供意见）、第6类（就机构融资提供意见）及第9类（提供资产管理）受规管活动。<br /><br />

          叶先生于投资、金融市场及银行行业拥有20年工作经验。彼自2020年9月起出任信银投资风险总监并自2018年5月起出任助理总经理。 2016年3月至2020年9月，彼曾担任信银（香港）资本有限公司总经理，负责风险管理、合规及其他领域。<br /><br />
          
          叶先生分别于1999年7月及2002年7月取得清华大学精密仪器学士学位及硕士学位。`,
        },
        {
          name: "• 李竹先生",
          id: "LIZhu",
          desc: `56岁，为执行董事兼联席首席运营官。彼主要负责本公司运营管理。李先生为发起人之一。<br /><br />

          自2013年3月起，李先生为英诺天使投资基金的创始合伙人，该基金为一项投资基金，专注于新能源、先进制造、新一代信息技术及生物技术行业。2012年，彼发起厚德创新谷孵化器。 2015年6月至2016年10月，彼曾于北京厚德文华投资咨询有限公司(Beijing Houde WenhuaInvestment Consulting Co., Ltd.)担任董事长。彼创立直播网络电视平台悠视网，并曾于2005年11月至2015年5月担任北京悠视互动科技有限公司董事长。<br /><br />
          
          李先生于1989年7月取得清华大学计算机科学学士学位。`,
        },
        {
          name: "• 陈耀超先生",
          id: "CHENYaochao",
          desc: `37岁，为执行董事兼联席首席运营官。彼主要负责本公司运营管理。陈先生获信银资本提名加入董事会。彼作为信银资本的负责人员（定义见证券及期货条例），已获证监会发牌自2019年8月起进行第9类（提供资产管理）受规管活动，并已获证监会发牌自2019年2月起为信银资本进行第1类（证券交易）及第4类（就证券提供意见）受规管活动。<br /><br />

          自2018年12月起，陈先生担任信银资本资产管理部负责人。 2017年12月至2018年10月，彼曾任职于中信建投（国际）金融控股有限公司（为中信建投证券股份有限公司（一家联交所（股份代号：6066）及上海证券交易所（股份代号：601066）上市公司）的附属公司）另类投资管理部，其最后职位为副总监。 2012 年 7 月至2017 年 10 月，彼曾任职于建银国际（中国）有限公司（为中国建设银行股份有限公司（一家联交所（股份代号：0939）及上海证券交易所（股份代号：601939）上市公司）的附属公司）。 2009年7月至2012年5月，彼曾任职于中国国际金融股份有限公司（一家联交所（股份代号：3908）及上海证券交易所（股份代号：601995）上市公司）。<br /><br />
          
          陈先生于2007年6月取得西南交通大学经济学学士学位。彼另于2014年6月取得中山大学岭南学院工商管理硕士学位。陈先生于2013年获特许金融分析师协会认可为特许金融分析师。`,
        },
        {
          name: "• 江君女士",
          id: "JIANGJun",
          desc: `40岁，为执行董事。彼主要负责本公司管理事宜。江女士获清科资本提名加入董事会，并为清科资本的董事。彼作为清科资本的负责人员（定义见证券及期货条例），已获证监会发牌自2022年1 月起为清科证券有限公司进行第1 类（证券交易）及第2类（期货合约交易）受规管活动，并自2021年11月起为清科资本进行第6类（就机构融资提供意见）受规管活动。<br /><br />

          自2021年7月起，江女士已担任清科国际控股有限公司（为清科创业控股的附属公司）首席执行官。2018年9月至2021年6月，彼曾担任富强金融资本有限公司首席执行官。2014 年1月至2018年9月，彼曾担任东方金融控股（香港）有限公司董事总经理兼投资银行部及全球资本市场部负责人。2010年2月至2013年11月，彼曾担任招商证券（香港）有限公司（为招商证券股份有限公司（一家联交所（股份代号：6099）及上海证券交易所（股份代号：600999）上市公司的附属公司）执行董事。2008年2月至2010年2月，彼曾任职招银国际金融有限公司（为招商银行股份有限公司（一家联交所（股份代号：3968）及上海证券交易所（股份代号：600036）上市公司的附属公司）。<br /><br />
          
          江女士于2003年6月取得东南大学法学学士学位，并于 2004年9月取得阿伯泰邓迪大学(University of Abertay Dundee)工商管理硕士学位，彼亦于2019年9月毕业于长江商学院高级管理人员工商管理硕士课程。`,
        },
      ],
    },
    {
      classify: "非执行董事",
      id: "NED",
      userInfo: [
        {
          name: "• 刘伟杰先生",
          id: "LAUWaiKit",
          desc: `59岁，为非执行董事。彼主要负责监督本公司管理。刘先生为发起人之一。<br/><br/>

          刘先生于投资、并购及企业管理方面拥有逾20年经验。自2014年12月起，彼已为Waterwood Investment合伙人，Waterwood Investment为一家私募股权公司，专注于医疗保健、技术及新经济行业的成长期投资机会。彼于2002年1月共同创立Gobi Ventures并于2014年12月前担任高级管理合伙人。 2000年8月至2001年3月，彼曾担任Asia2B.com的首席财务官。1998年9月至2000年3月，彼曾任职于Wah Tak Management Limited，其最后职位为执行董事。 1997年4月至1999年3月，彼曾担任海裕金融集团副董事长兼董事。在此之前，刘先生曾于1988年9月至1995年5月任职于多家律师事务所，包括为苏姜律师行及苏姜叶律师行的合伙人及贝克•麦坚时的律师。<br/><br/>
          
          刘先生于1985年5月取得香港大学法学学士学位，并于1986年5月取得法学研究生证书。刘先生具有香港、加州、新加坡、英格兰及威尔士法律执业资格。`,
        },
      ],
    },
    {
      classify: "独立非执行董事",
      id: "IED",
      userInfo: [
        {
          name: "• ZHANG Min先生",
          id: "ZHANGMin",
          desc: `53岁，为独立非执行董事。彼主要负责监察并向董事会提供独立意见。<br/><br/>

          自2012年9月起，Zhang先生已担任上海合之力投资管理有限公司总经理。在此之前，Zhang先生曾先后于2005年12月至2008年10月担任晨兴资讯科技咨询（上海）有限公司业务拓展总监、于2002年12月至2005年12月担任上海梅迪派勒广告有限公司副总裁，以及于2001年3月至2002 年11 月担任罗兵咸永道会咨询（深圳）有限公司上海分公司的风险控制部高级经理。自2019年10月至2020年12月，彼已担任Greenland Technologies Holding Corporation（一家纳斯达克证券交易所上市公司，股份代号：GTEC）独立董事。自2020年12月起，彼亦已担任清科创业控股独立非执行董事。<br/><br/>
          
          Zhang先生于1989年7月取得四川大学经济学学士学位，并于1995年春季学期取得挪威经济与工商管理学院(The Norwegian School of Economics and Business Administration)国际商务硕士学位。`,
        },
        {
          name: "• 薛林楠先生",
          id: "XUELinnan",
          desc: `49岁，为独立非执行董事。彼主要负责监察并向董事会提供独立意见。<br/><br/>
          自2021年4月起，彼已担任深睿高科技有限公司首席财务官，主要负责整体融资管理及风
          险控制。 2013 年4 月至2020 年12 月，薛先生曾先后担任复星国际有限公司（一家联交所上市公司，股份代号：0656）审计部、客户服务及产品质量监察部的总经理以及首席财务官，并担任复星蜂巢副主席，主要负责内部审计、财务尽职调查、融资管理及房地产投资及经营。<br/><br/>
          
          薛先生于1997年9月取得中国人民大学国际税务学士学位，并于2001年9月取得波士顿大学(Boston University)经济学硕士学位。彼自2002年2月起为美国注册会计师协会会员，并自2006年11月为美国内部控制研究院(American Institute of Internal Control)会员。`,
        },
        {
          name: "• 李卫锋博士",
          id: "LIWeifeng",
          desc: `44岁，为独立非执行董事。彼主要负责监察并向董事会提供独立意见。<br/><br/>

          李博士自2011年7月起于香港大学担任多项职务，先后担任香港大学城市规划及设计系助
          理教授、副教授以及副系主任，且彼自2021年9月起担任香港大学建筑学院副院长。<br/><br/>
          
          李博士于2001年6月取得北京大学地理及经济的双学士学位。彼亦于2004年6月取得北京大学地理硕士学位并于2015年2月于麻省理工学院(Massachusetts Institute of Technology)取得城市与区域规划的博士学位。`,
        },
      ],
    },
  ],
  SMT: {
    classify: "高级管理层",
    id: "SMT",
    desc: ``,
    userInfo: [
      {
        name: "倪正东",
        sex: "先生",
        id: "Zhengdong",
        desc: `董事会主席、执行董事<br/>兼联席首席执行官`,
      },
      {
        name: "叶青",
        sex: "先生",
        id: "YEQing",
        desc: `执行董事兼联席首席执行官`,
      },
      {
        name: "李竹",
        sex: "先生",
        id: "LIZhu",
        desc: `执行董事兼联席首席运营官`,
      },
      {
        name: "陈耀超",
        sex: "先生",
        id: "CHENYaochao",
        desc: `执行董事兼联席首席运营官`,
      },
    ],
  },
  BC: {
    classify: "董事委员会名单",
    id: "BC",
    desc: ``,
    userInfo: [
      {
        name: "• 审核委员会",
        id: "AC",
        desc: `薛林楠先生（主席）、ZHANG Min先生、李卫锋博士`,
      },
      {
        name: "• 薪酬委员会",
        id: "RC",
        desc: `李卫锋博士（主席）、李竹先生、ZHANG Min先生`,
      },
      {
        name: "• 提名委员会",
        id: "NC",
        desc: `倪正东先生（主席）、李卫锋博士、ZHANG Min先生`,
      },
    ],
  },
};
