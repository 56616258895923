export default {
  text1:
    "我们贯彻集团策略，将主要寻求收购一项或多项高增长业务，并已确定以下我们相信对评估潜在特殊目的收购公司并购目标属重要的一般标准及指引：",
  text2:
    "该等标准并非详尽无遗。在相关情况下，有关特定特殊目的收购公司并购交易优势的任何评估可能基于该等一般指引，以及董事会可能认为与我们寻找特殊目的收购公司并购目标相关的其他考量、因素及标准。尽管我们拟遵循该等用以评估潜在特殊目的收购公司并购目标的指引及标准，但我们与其进行特殊目的收购公司并购交易的特殊目的收购公司并购目标可能不符合该等指引及标准。请参阅「风险因素 － 与本公司及特殊目的收购公司并购交易有关的风险 － 我们可能在不属于我们发起人及董事的专业领域以内或未必符合我们认定标准及指引的行业或领域寻求特殊目的收购公司并购目标」。",
  textList: [
    "优质并在具差异化价值主张及产品或服务壁垒的新经济领域具有竞争优势；",
    "与经济趋势及国家产业政策一致；",
    "理想的长期增长前景；",
    "具差异化产品及服务的大型消费或业务市场；",
    "独特竞争优势或我们的团队于选取未充分利用的增长机会有独特的优势；",
    "能为特殊目的收购公司并购目标创造重大价值，强大且富有远见的管理团队；",
    "贯彻环境、社会及管治价值观，恪守道德、专业及负责任的管理层；及",
    "自成为公众公司中受益。",
  ],
};
