<template>
  <div class="model_view">
    <b-modal
      v-model="modalShow"
      hide-header
      static
      centered
      modal-class="i_model"
      dialog-class="i_dialog"
      content-class="i_content"
      body-class="i_body"
      no-close-on-backdrop
    >
      <div class="reminder-title font-size-16 mb20">
        <p><b>IMPORTANT&nbsp; NOTICE</b></p>
        <p><b>重要提示</b></p>
      </div>
      <div class="reminder-content" ref="reminderContent">
        <div class="step-1" v-if="steps === 1">
          <p>
            Access to these materials is restricted in certain
            jurisdictions.Please indicate your country of residence:
          </p>
          <p class="mb20">
            在某些司法管辖区取阅该等材料会受到限制。请表明阁下的居住所在地：
          </p>
          <b-form-select
            class="mb48"
            v-model="selected"
            :options="options"
            size="sm"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled
                >Country of Residence（居住地）</b-form-select-option
              >
            </template>
          </b-form-select>
        </div>
        <div class="step-2" v-if="steps === 2">
          <div class="step-2">
            <div class="mb24">
              <p>Are you a U.S. person as defined below?</p>
              <p>阁下是否属于下述定义之美籍人士？</p>
            </div>
            <div class="mb24">
              <b-form-group label="">
                <b-form-radio-group
                  v-model="selected2"
                  :options="step2Options"
                  plain
                  name="plain-inline"
                ></b-form-radio-group>
              </b-form-group>
            </div>
            <div class="mb24">
              <p>Definition of U.S. person under Regulations:</p>
              <p>S规例下美籍人士的定义：</p>
            </div>
            <div class="mb24">
              <p>
                Under Rule 902 of Regulation S under the United States
                Securities Act of 1993,as amended, the term U.S. person means:
              </p>
              <p>
                根据1933年美国证券法（经修订）下S规例第902条，美籍人士一词指：
              </p>
            </div>
            <ul class="mb24">
              <li><p>any natural person resident in the United States;</p></li>
              <p>任何居于美国的自然人；</p>
            </ul>
            <ul class="mb24">
              <li>
                <p>
                  any partnership or corporation organized or incorporated under
                  the laws of the United States;
                </p>
              </li>
              <p>根据美国法律组建或注册成立的任何合伙机构或法团；</p>
            </ul>
            <ul class="mb24">
              <li>
                <p>
                  any estate of which an executor or administrator is a U.S.
                  person;
                </p>
              </li>
              <p>由美籍人士担任执行人或管理人的遗产；</p>
            </ul>
            <ul class="mb24">
              <li><p>any trust of which any trustee is a U.S. person;</p></li>
              <p>由美籍人士担任受托人的信托；</p>
            </ul>
            <ul class="mb24">
              <li>
                <p>
                  any agency or branch of a foreign entity located in the United
                  States;
                </p>
              </li>
              <p>位于美国的外国实体旗下代理或分支机构；</p>
            </ul>
            <ul class="mb24">
              <li>
                <p>
                  any non-discretionary account or similar account (other than
                  an estate or trust) held by a dealer or other fiduciary for
                  the benefit or account of a U.S. person;
                </p>
              </li>
              <p>
                交易商或其他受信人为某美籍人士的利益或为某美籍人士而持有的任何非全权账户或类似账户（遗产或信托除外）；
              </p>
            </ul>
            <ul class="mb24">
              <li>
                <p>
                  any partnership or corporation if: (a) organized or
                  incorporated under the laws of any foreign jurisdiction; and
                  (b) formed by a U.S. person principally for the purpose of
                  investing in securities not registered under the Securities
                  Act,unless it is organized or incorporated,and owned, by
                  accredited investors (as defined in Rule 501(a) promulgated
                  under the Securities Act) who are not natural persons, estates
                  or trusts.
                </p>
              </li>
              <p>
                下述任何合伙机构或法团：(a)
                根据任何外国司法管辖区法律组建或注册成立；及 (b)
                由美籍人士主要就投资于尚未根据证券法登记的证券之目的所组成，除非该合伙机构或法团是由不属于自然人、遗产或信託之认可投资者（定义见美国证券法第501(a)条）所组建或注册成立并拥有。
              </p>
            </ul>
            <div class="mb24">
              <p>The following are not “U.S. persons”:</p>
              <p>下列人士并非美籍人士：</p>
            </div>
            <ul class="mb24">
              <li>
                <p>
                  any discretionary account or similar account (other than an
                  estate or trust) held for the benefit or account of a non-
                  U.S. person by a dealer or other professional fiduciary
                  organized, incorporated, or (if an individual) resident in the
                  United States;
                </p>
              </li>
              <p>
                由在美国组建、注册成立或（倘属个人）居住于美国之交易商或其他专业受信人为某美籍人士的利益或为某美籍人士而持有之任何全权账户或类似账户（遗产或信託除外）；
              </p>
            </ul>
            <ul class="mb24">
              <li>
                <p>
                  any estate of which any professional fiduciary acting as
                  executor or administrator is a U.S. person if: (a) an executor
                  or administrator of the estate who is not a U.S. person has
                  sole or shared investment discretion with respect to the
                  assets of the estate; and (b) the estate is governed by
                  foreign law;any trust of which any professional fiduciary
                  acting as trustee is a U.S. person, if a trustee who is not a
                  U.S. person has sole or shared investment discretion with
                  respect to the trust assets, and no beneficiary of the trust
                  (and no settlor if the trust is revocable) is a U.S. person;
                </p>
              </li>
              <p>
                由任何属于美籍人士的专业受信人作为执行人或管理人之任何遗产，前提是：(a)
                不属于美籍人士的遗产执行人或管理人就遗产的资产拥有唯一的或相互共享投资决定权；及
                (b)
                遗产的信託资产受外国法律规管，而且信託的受益人（若属于可予撤销的信託，则为财产授予人）并非美籍人士；
              </p>
            </ul>
            <ul class="mb24">
              <li>
                <p>
                  an employee benefit plan established and administered in
                  accordance with the law of a country other than the United
                  States and customary practices and documentation of such
                  country.
                </p>
              </li>
              <p>
                根据美国以外国家之法律及惯例及文件成立及管理之僱员福利计划；
              </p>
            </ul>
            <ul class="mb24">
              <li>
                <p>
                  any agency or branch of a U.S. person located outside the
                  United States if: (a) the agency or branch operates for valid
                  business reasons; and (b) the agency or branch is engaged in
                  the business of insurance or banking and is subject to
                  substantive insurance or banking regulation, respectively,in
                  the jurisdiction where located;
                </p>
              </li>
              <p>
                位于美国境外之美籍人士之任何代理或分支机构，前提是：(a)
                代理或分支机构根据合法业务理由营运；及 (b)
                代理或分支机构从事保险或银行业务，并须分别在其所在司法管辖区遵守实质的保险或银行规例；
              </p>
            </ul>
            <ul class="mb48">
              <li>
                <p>
                  the International Monetary Fund, the International Bank for
                  Reconstruction and Development, the Inter-American Development
                  Bank, the Asian Development Bank, the African Development
                  Bank, the United Nations, and their agencies, affiliates and
                  pension plans, and any other similar international
                  organizations, their agencies, affiliates and pension plans;
                </p>
              </li>
              <p>
                国际货币基金组织、国际复兴开发银行、美洲开发银行、亚洲开发银行、非洲开发银行、联合国及其代理机构、联属组织及退休金计划，以及任何其他类似国际组织、其代理机构、联属组织及退休金计划。
              </p>
            </ul>
          </div>
        </div>
        <div class="step-3" v-if="steps === 3">
          <div class="mb48">
            <p>
              We regret that, due to regulatory restrictions,we are unable to
              provide you with access to this section of our website.
            </p>
            <p>
              谨此致歉，基于监管限制，本公司无法允许阁下取阅本公司网站内此部分的资料。
            </p>
          </div>
        </div>
        <div class="step-4" v-if="steps === 4">
          <div class="mb24">
            <p>
              <b
                >ELECTRONIC VERSIONS OF THE MATERIALS YOU ARE SEEKING TO ACCESS
                ARE BEING MADE AVAILABLE ON THIS WEBSITE IN GOOD FAITH AND ARE
                FOR INFORMATION PURPOSES ONLY.</b
              >
            </p>
            <p>阁下准备取阅的材料之电子版本乃秉诚载于此网站，仅供参考之用。</p>
          </div>
          <div class="mb24">
            <p>
              <b
                >THESE MATERIALS ARE NOT DIRECTED AT OR ACCESSIBLE BY PERSONS IN
                THE UNITED STATES OR U.S. PERSONS (WITHIN THE MEANING OF
                REGULATIONS UNDER THE U.S. SECURITIES ACT, AS AMENDED), OR
                PERSONS RESIDENT OR LOCATED IN CANADA OR JAPAN OR ANY OTHER
                JURISDICTION WHERE THE EXTENSION OF AVAILABILITY OF THE
                MATERIALS TO WHICH YOU ARE SEEKING ACCESS WOULD BREACH ANY
                APPLICABLE LAW OR REGULATION.</b
              >
            </p>
            <p>
              该等材料并非以下述人士为对象或可由以下人士取阅：位于美国的人士或美籍人士（定义见美国证券法（经修订）S规则）或者居于或位于加拿大或日本或者分派或提供阁下准备取阅之材料即会违反任何适用法律或法规的其他司法管辖区之人士。
            </p>
          </div>
          <div class="mb48">
            <p>
              The materials do not constitute or form a part of any offer or
              solicitation to purchase or subscribe for securities in the United
              States,Canada or Japan or in any jurisdiction in which such offers
              or sales are unlawful (the “Excluded Territories”). Any securities
              issued in connection with an offering have not been and will not
              be registered under the U.S.Securities Act of 1933, as amended, or
              under any applicable securities laws of any state, province,
              territory, county or jurisdiction of the United States, Canada or
              Japan. Accordingly,unless an exemption under relevant securities
              laws is applicable, any such securities may not be offered, sold,
              resold, taken up, exercised, renounced, transferred, delivered or
              distributed, directly or indirectly, in or into the United States,
              Canada or Japan or any other jurisdiction if to do so would
              constitute a violation of the relevant laws of, or require
              registration of such securities in, the relevant jurisdiction.
              There will be no public offer of securities in the United States.
            </p>
            <p>
              该等材料概不构成或作为在美国、加拿大或日本或者作出证券购买或认购要约或出售即属违法的任何司法管辖区（“除外地区”）作出购买或认购证券的任何要约或招揽的一部分。就发售而发行的任何证券并未，亦不会，根据1933年美国证券法（经修订）或根据美国、加拿大或日本的任何州、省、领土、县或司法管辖区的任何适用证券法律登记。因此，除非根据相关证券法律获得适用豁免，否则不得直接或间接在下述地区或向下述地区提呈、出售、转售、认购、行使、放弃、转让、交付或分配任何该等证券：美国、加拿大或日本或倘若如此行事即会构成违反相关司法管辖区的相关法律或需要在相关司法管辖区登记该等证券的任何其他司法管辖区。证券将不会在美国进行公开发售。
            </p>
          </div>
        </div>
        <div class="step-5" v-if="steps === 5">
          <div class="mb48">
            <p>
              You are about to access the electronical version of the offering
              circular (the “Offering Circular”) of TechStar Acquisition
              Corporation. (the “Company”). All terms used herein shall have the
              same meaning as defined in the Offering Circular. The Offering
              Circular is to be distributed to Professional Investors only. By
              accessing the Offering Circular, you represent that you are a
              Professional Investor. The Offering is made solely on the basis of
              the information contained and the representations made in the
              Offering Circular. Neither the Company nor any of the Relevant
              Persons has authorised anyone to provide you with any information
              or to make any representation that is different from what is
              contained in the Offering Circular. Any information or
              representation not made in the Offering Circular must not be
              relied on by you as having been authorised by the Company or any
              of the Relevant Persons.
            </p>
            <p>
              阁下将取阅 TechStar Acquisition
              Corporation.（“本公司”）刊发的发售通函（“发售通函”）电子版。本通知所用词汇与发售通函所界定者具有相同涵义。发售通函仅派发予专业投资者。阁下读取发售通函，即声明阁下为专业投资者。发售仅根据发售通函所载资料及所作声明进行。本公司及任何相关人士概无授权任何人士向阁下提供任何资料或作出有别于发售通函所载内容的任何声明。阁下不应将发售通函未作出的任何资料或陈述，视为已获得本公司或任何相关人士授权而加以依赖。
            </p>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="model_footer">
          <b-button
            v-if="steps === 1 || steps === 2 || steps === 3"
            :variant="cDisabled ? 'outline-secondary' : 'primary'"
            class="confirm_button font-size-16"
            @click="confirm"
            :disabled="cDisabled"
            >OK</b-button
          >
          <div
            class="db_btn"
            :style="steps === 5 ? 'justify-content:center' : ''"
            v-else-if="steps === 4 || steps === 5"
          >
            <b-button
              variant="outline-dark"
              class="disagree"
              v-if="steps === 4"
              @click="disagree"
              >Disagree（不同意）</b-button
            >
            <b-button variant="primary" class="agree" @click="agree"
              >Agree（同意）</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalShow: false,
      selected: null,
      options: [
        { value: "hk", text: "Hong Kong（香港）" },
        { value: "b", text: "Canada（加拿大）" },
        { value: "c", text: "Japan（日本）" },
        { value: "d", text: "United States（美国）" },
        { value: "other", text: "Other（其他）" },
      ],
      selected2: null,
      step2Options: [
        { text: "Yes（是）", value: "Y" },
        { text: "No（否）", value: "N" },
      ],
      steps: 1,
    };
  },
  computed: {
    cDisabled() {
      if (this.steps === 1 && !this.selected) return true;
      else if (this.steps === 2 && !this.selected2) return true;
      else return false;
    },
  },
  watch: {
    visible(val) {
      this.modalShow = val;
      if (!val) {
        this.steps = 1;
        this.selected = null;
        this.selected2 = null;
      }
    },
    steps() {
      if (this.$refs.reminderContent) this.$refs.reminderContent.scrollTop = 0;
    },
    // modalShow(val) {
    //   this.$emit("input", val);
    //   if (!val) {
    //     this.steps = 1;
    //     this.selected = null;
    //     this.selected2 = null;
    //   }
    // },
  },
  created() {},
  methods: {
    ...mapMutations(["SET_CONFIRMED", "SET_SHOWMODEL"]),
    disagree() {
      this.steps = 3;
    },
    agree() {
      if (this.steps === 4) this.steps = 5;
      else {
        // this.modalShow = false;
        this.SET_SHOWMODEL(false);
        this.SET_CONFIRMED("yes");
      }
    },
    confirm() {
      if (this.steps === 1) {
        if (this.selected === "hk" || this.selected === "other") this.steps = 2;
        else if (this.selected) this.steps = 3;
      } else if (this.steps === 2) {
        if (this.selected2 === "N") this.steps = 4;
        else if (this.selected2 === "Y") this.steps = 3;
      } else if (this.steps === 3) {
        // this.modalShow = false;
        this.SET_SHOWMODEL(false);
      } else if (this.steps === 4) {
        if (this.selected === "Y") this.steps = 2;
      }
    },
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.font-size-16 {
  font-size: 16px;
}
.mb20 {
  margin-bottom: 1.04167vw !important;
}
.mb24 {
  margin-bottom: 1.25vw !important;
}
.mb48 {
  margin-bottom: 2.5vw !important;
}
.pd-0 {
  padding: 0;
}
.model_view {
  text-align: justify;
  :deep(.i_model) {
    .i_dialog {
      padding: 0 12px;
    }
    .i_content {
      border: none;
    }
    .i_body {
      padding: 0;
      padding: 24px;
      font-size: 14px;
      line-height: 1.8715;
      word-break: break-all;
    }
    .model_footer {
      width: 100%;
    }
    .db_btn {
      display: flex;
      justify-content: space-between;
    }
    .agree,
    .disagree {
      font-size: 16px;
    }
    .confirm_button {
      width: 100%;
    }
    .reminder-title {
      text-align: center;
    }
    .reminder-content {
      padding: 0 12px;
      color: #4a4a4a;
      font-size: 14px;
      max-height: 50vh;
      overflow: auto;
      p {
        margin-bottom: 0.3em;
      }
      li {
        margin-left: 1em;
        & + p {
          margin-left: 1em;
        }
      }
    }
  }
  @media (max-width: 789px) {
    :deep(.i_model) {
      .agree,
      .disagree {
        font-size: 14px;
      }
    }
  }
}
</style>
