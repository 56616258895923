export default {
  text1:
    "Consistent with our strategy, we will primarily seek to acquire one or more high-growth businesses and have identified the following general criteria and guidelines that we believe are important in evaluating prospective De-SPAC Targets:",
  text2:
    "These criteria are not intended to be exhaustive. Any evaluation relating to the merits of a particular De-SPAC Transaction may be based, to the extent relevant, on these general guidelines as well as on other considerations, factors and criteria that our Board may deem relevant to our search for a De-SPAC Target. While we intend to follow these guidelines and criteria for evaluating potential De-SPAC Targets, it is possible that the De-SPAC Target(s) with which we enter into a De SPAC Transaction will not meet these guidelines and criteria. See “Risk Factors— Risks relating to our Company and the De-SPAC Transaction— We may seek De-SPAC Targets in industries or sectors that may be outside of our Promoters’ and our Directors’ areas of expertise or that may not meet our identified criteria and guidelines.”",
  textList: [
    "high-quality with competitive edges in a new economy sector with a differentiated value proposition and product or service barriers;",
    "alignment with economic trends and national industrial policies;",
    "favorable long-term growth prospects;",
    "large consumer or business market with differentiated products and services;",
    "distinct competitive advantages or under-tapped growth opportunities that our team is uniquely positioned to identify;",
    "strong and visionary management team that can create significant value for the De-SPAC Target;",
    "an ethical, professional and responsible management in pursuit of ESG values; and",
    "benefit from being a public company.",
  ],
};
