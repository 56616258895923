export default {
  text1:
    "我们的目标是通过选择优质的特殊目的收购公司并购目标、以具吸引力的估值磋商有利的收购条款及为改善继承公司的营运及财务表现奠定基础，从而为我们的股东创造具吸引力的回报。尽管我们不限于并可能于任何行业或地区寻求特殊目的收购公司并购目标，但我们拟重点关注于新经济领域营运的公司，包括但不限于创新科技、先进制造、医疗保健、生命科学、文娱产业、消费及新零售、绿色能源及气候行动等行业。该等公司将利用科技促进其于各自行业领域的增长及发展，我们相信此将与我们的发起人及董事的专业知识形成良好互补。",
  text2:
    "我们预期利用我们的发起人及董事稳固的关系网络、行业专业知识及成熟的项目获取能力，发展稳固的潜在目标储备。",
  text3: "为实现我们的业务策略，我们拟于以下方面发挥我们的经验：",
  textList: [
    "通过发起人的广泛网络获取投资或收购机会；",
    "识别具长期增长潜力的优质特殊目的收购公司并购目标；",
    "评估及进行公司特定分析及尽职调查；",
    "磋商、构建及执行并购及其他资本市场交易；",
    "投资、经营及就于新经济领域的交易及公司提供意见；",
    "扩张及加强与行业领导者及利益相关者的合伙关系；",
    "为公司提供有关营销、品牌、一般业务经营、人才招聘及财务事务的咨询意见；及",
    "通过金融服务及业内网络为继承公司赋能",
  ],
};
