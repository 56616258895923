import overview from "@/lang/moudle/overview/cn";
import promoters from "@/lang/moudle/promoters/cn";
import governance from "@/lang/moudle/governance/cn";
import routers from "@/lang/moudle/router/cn";
import strategy from "@/lang/moudle/strategy/cn";
import home from "@/lang/moudle/home/cn";
import despac from "@/lang/moudle/deSpac/cn";
import investor from "@/lang/moudle/investor/cn";

export default {
  websiteName: "Techstar",
  home,
  overview,
  promoters,
  governance,
  routers,
  strategy,
  despac,
  investor,
};
