import Vue from "vue";
import VueRouter from "vue-router";
import Overview from "../views/Overview/index.vue";
import i18n from "../lang/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Overview",
    component: Overview,
    meta: {
      title: "routers.overview",
    },
  },
  /** 2022/11/1
   * @作者:BarneyJiang
   * @功能:发起人
   * @param:
   */
  {
    path: "/promoters",
    name: "Promoters",
    meta: {
      title: "routers.promoters",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Promoters/index.vue"),
  },
  /** 2022/11/1
   * @作者:BarneyJiang
   * @功能:战略
   * @param:
   */
  {
    path: "/strategy",
    name: "Strategy",
    meta: {
      title: "routers.strategy",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Strategy/index.vue"),
  },
  /** 2022/11/1
   * @作者:BarneyJiang
   * @功能:de-spac标准
   * @param:
   */
  {
    path: "/de-spac",
    name: "DeSpac",
    meta: {
      title: "routers.deSpac",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DeSpac/index.vue"),
  },
  /** 2022/11/1
   * @作者:BarneyJiang
   * @功能:公司治理
   * @param:
   */
  {
    path: "/governance",
    name: "Governance",
    meta: {
      title: "routers.government",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Governance/index.vue"),
  },
  /** 2022/11/1
   * @作者:BarneyJiang
   * @功能:投资者关系
   * @param:
   */
  {
    path: "/investor",
    name: "Investor",
    meta: {
      title: "routers.investor",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Investor/index.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    } // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 1 });
      }, 0);
    });
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = i18n.tc(to.meta.title);
  }
  next();
});

export default router;
