export default {
  fileList: [
    {
      name: "uioioeklqwjklejkljkl",
      url: "https://www.baidu.com",
      time: "2022-12-12",
    },
    {
      name: "阿数据库里阿斯达",
      url: "https://www.baidu.com",
      time: "2022-12-12",
    },
    {
      name: "电饭锅地方电饭锅地方",
      url: "https://www.baidu.com",
      time: "2022-12-12",
    },
    {
      name: "啊啊三端通用入入如松岛枫松岛枫水电费人与他体育她与他有个孙立峰的是考虑到非SDK分手快乐;的圣诞快乐; 说了;打客服说的;克莱斯勒;打客女孩高发过火发过火复工后复工后复工后啼眼人",
      url: "https://www.baidu.com",
      time: "2022-12-12",
    },
  ],
  nothing: "暂无数据",
};
