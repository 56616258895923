import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import "@/styles/app.scss";

import "@/styles/index.scss";

import "animate.css";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import i18n from "@/lang";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
