<template>
  <div class="home">
    <div class="banner"></div>
    <BannerDiv
      :imgSrc="{
        pc: require('@/assets/home/banner.png'),
        phone: require('@/assets/home/banner_phone.png'),
      }"
    />
    <div class="container_box">
      <b-container fluid>
        <b-row no-gutters>
          <b-col class="title animate__animated animate__fadeInUp">
            <div v-html="$t('overview.title')"></div>
            <div class="desc" v-html="$t('overview.desc')"></div>
          </b-col>
        </b-row>
        <b-row no-gutters class="content animate__animated animate__fadeInUp">
          <b-col md>
            <span class="d_title" v-html="$t('overview.d_title')" />
            <span v-html="$t('overview.p1')" />
            <span v-html="$t('overview.p2')" />
          </b-col>
          <b-col md offset-md="1">
            <span v-html="$t('overview.p3')" />
            <span v-html="$t('overview.p4')" />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import BannerDiv from "@/components/BannerDiv.vue";
export default {
  name: "HomeView",
  components: {
    BannerDiv,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.home {
  .title {
    font-size: 36px;
    color: #252626;
    font-weight: 500;
    line-height: 36px;
    margin-top: 80px;
    text-align: left;
    .desc {
      font-size: 18px;
      color: #bbbdbf;
      line-height: 18px;
      font-weight: 400;
      margin-top: 9px;
    }
  }
  .content {
    line-height: 1.8;
    font-size: 14px;
    color: #575859;
    text-align: justify;
    margin-top: 70px;
    padding-bottom: 80px;
    .d_title {
      color: #252626;
      font-weight: bold;
    }
  }
  @media (max-width: 789px) {
    .title {
      margin-top: 32px;
      font-size: 32px;
    }
    .content {
      margin-top: 32px;
      padding-bottom: 32px;
    }
  }
}
</style>
