export default {
  title: "Corporate Governance",
  title_desc:
    "Members of our team have extensive investment and advisory experience, with an established track record of investments in companies across a range of sectors and in different growth stages. As such, our team possesses strong capabilities to offer creative solutions for complex transactions. In addition, our team has a well-rounded and complementary set of skills and experience relevant to our acquisition strategy. The collective experience of our team provides us with a competitive advantage in identifying and partnering with a high-quality De-SPAC Target and supporting the Successor Company’s long-term growth through our active involvement.",
  top_nav: [
    {
      classify: "Executive Directors",
      id: "ED",
    },
    {
      classify: "Non-executive Director",
      id: "NED",
    },
    {
      classify: "Independent Non-executive Directors",
      id: "IED",
    },
    {
      classify: "Senior Management Team",
      id: "SMT",
    },
    {
      classify: "Board Committees",
      id: "BC",
    },
  ],
  detail_info: [
    {
      classify: "Executive Directors",
      id: "ED",
      userInfo: [
        {
          name: "• Mr. NI Zhengdong (倪正东)",
          id: "Zhengdong",
          desc: `aged 48, has been our Director since the incorporation of our Company in April 2022, and was re-designated as the chairman of the Board and our executive Director and appointed as our co-chief executive officer in June 2022. He is primarily responsible for the formulation of overall strategic direction and management of our Company. Mr. Ni is one of our Promoters and is a director of Zero2IPO Capital.<br /><br />

          Mr. Ni has over 20 years of experience in the equity investment industry. He started the business of Zero2IPO Group and Zero2IPO Holdings in 2001 and was appointed as the executive director and then as the chairman of Zero2IPO Group since its inception in 2005. Mr. Ni is also the chairman, an executive Director and chief executive officer of Zero2IPO Holdings, a company listed on the Stock Exchange (stock code: 1945), where he is responsible for the overall management of business, strategy and corporate development.<br /><br />
          
          Mr. Ni obtained a bachelor’s degree in engineering mechanics from Hunan University (湖南大学) in July 1996, and a master’s degree in engineering mechanics from Tsinghua University (清华大学) in January 2000. He also graduated from a business administration PhD program from Tsinghua University in January 2007.`,
        },
        {
          name: "• Mr. YE Qing (叶青)",
          id: "YEQing",
          desc: ` aged 44, is our executive Director and co-chief executive officer. He is primarily responsible for the formulation of the business direction and management of our Company. Mr. Ye was nominated to the Board by CNCB Capital and is a director of CNCB Capital. He has been licensed as a responsible officer (as defined under the SFO) of CNCB Capital by the SFC to carry out Type 1 (dealing in securities), Type 4 (advising on securities), Type 6 (advising on corporate finance) and Type 9 (asset management) regulated activities since March 2017.<br /><br />

          Mr. Ye has 20 years of work experience in investment, financial market and banking industry. He has been a risk director at CNCB Investment since September 2020 and as an assistant general manager since May 2018. From March 2016 to September 2020, he served as a general manager at CNCB (Hong Kong) Capital Limited, where he was responsible for the risk management, compliance and other sectors.<br /><br />
          
          Mr. Ye obtained a bachelor’s degree and a master’s degree in precision instrument from Tsinghua University (清华大学) in July 1999 and July 2002, respectively.`,
        },
        {
          name: "• Mr. LI Zhu (李竹)",
          id: "LIZhu",
          desc: `aged 56, is our executive Director and co-chief operation officer. He is primarily responsible for the management of the operation of our Company. Mr. Li is one of our Promoters. Mr. Li is the founding partner of Innoangel Fund (英诺天使投资基金) since March 2013, which is an investment fund focusing on new energy, advanced manufacturing, new generation information technology and biotech industry. In 2012, he launched the Houde Innovation Valley (厚德创新谷), which is an incubator. From June 2015 to October 2016, he served as the chairman of Beijing Houde Wenhua Investment Consulting Co., Ltd. (北京厚德文华投资谘询有限公司). He founded the UUSee (悠视网), a live online television platform, and served as the chairman of the board of Beijing UUsee Interactive Technology Co., Ltd. (北京悠视互动科技有限公司) from November 2005 to May 2015.<br /><br />

          Mr. Li obtained a bachelor’s degree in computer science from Tsinghua University (清华大学) in July 1989.`,
        },
        {
          name: "• Mr. CHEN Yaochao (陈耀超)",
          id: "CHENYaochao",
          desc: `aged 37, is our executive Director and co-chief operation officer. He is primarily responsible for the management of the operation of our Company. Mr. Chen was nominated to the Board by CNCB Capital. He has been licensed as a responsible officer (as defined under the SFO) of CNCB Capital by the SFC to carry out Type 9 (asset management) regulated activities since August 2019, and has been licensed by the SFC to carry out Type 1 (dealing in securities) and Type 4 (advising on securities) regulated activities for CNCB Capital since February 2019.<br /><br />

          Mr. Chen served as the head of asset management department at CNCB Capital since December 2018. He worked at the alternative investment management department of China Securities (International) Finance Holding Company Limited, a subsidiary of CSC Financial Co., Ltd., a company listed on the Stock Exchange (stock code: 6066) and on the Shanghai Stock Exchange (stock code: 601066), from December 2017 to October 2018 with his last position held as an associate director. From July 2012 to October 2017, he worked at CCB International (China) Limited, a subsidiary of China Construction Bank Corporation, a company listed on the Stock Exchange (stock code: 0939) and on the Shanghai Stock Exchange (stock code: 601939). From July 2009 to May 2012, he worked at China International Capital Corporation Limited, a company listed on the Stock Exchange (stock code: 3908) and on the Shanghai Stock Exchange (stock code: 601995).<br /><br />
          
          Mr. Chen obtained a bachelor’s degree in economics from Southwest Jiaotong University (西南交通大学) in June 2007. He further obtained an MBA degree from Lingnan College of Sun Yat-Sen University in June 2014. Mr. Chen was recognized as a Chartered Financial Analyst by CFA Institute in 2013.`,
        },
        {
          name: "• Ms. JIANG Jun (江君)",
          id: "JIANGJun",
          desc: `aged 40, is our executive Director. She is primarily responsible for the management matters of our Company. Ms. Jiang was nominated to the Board by Zero2IPO Capital and is a director of Zero2IPO Capital. She has been licensed as a responsible officer (as defined under the SFO) of Zero2IPO Capital by the SFC to carry out Type 1 (dealing in securities) and Type 2 (dealing in futures contracts) regulated activities since January 2022 for Zero2IPO Securities Limited and Type 6 (advising on corporate finance) regulated activities since November 2021.<br /><br />

          Ms. Jiang has served as the chief executive officer of Zero2IPO International Holdings Limited, a subsidiary of Zero2IPO Holdings, since July 2021. From September 2018 to June 2021, she served as the chief executive officer at Fortune Financial Capital Limited. From January 2014 to September 2018, she served as a managing director and the head of investment banking department and global capital market department at Orient Finance Holdings (Hong Kong) Limited. From February 2010 to November 2013, she served as an executive director of investment banking department at China Merchants Securities (Hong Kong) Co., Ltd., a subsidiary of China Merchants Securities Co., Ltd., a company listed on the Stock Exchange (stock code: 6099) and on the Shanghai Stock Exchange (stock code: 600999). From February 2008 to February 2010, she worked at CMB International Capital Corporation Limited, a subsidiary of China Merchants Bank Co., Ltd., a company listed on the Stock Exchange (stock code: 3968) and on the Shanghai Stock Exchange (stock code: 600036).<br /><br />
          
          Ms. Jiang obtained a bachelor’s degree in law from Southeast University (东南大学) in June 2003 and an MBA degree from University of Abertay Dundee in September 2004. She also graduated from an EMBA program from Cheung Kong Graduate School of Business in September 2019.`,
        },
      ],
    },
    {
      classify: "Non-executive Director",
      id: "NED",
      userInfo: [
        {
          name: "• Mr. LAU Wai Kit (刘伟杰)",
          id: "LAUWaiKit",
          desc: `aged 59, is our non-executive Director. He is primarily responsible for oversight of the management of our Company. Mr. Lau is one of our Promoters.<br /><br />

          Mr. Lau has over 20 years of experience in investment, mergers, acquisitions and corporate management. He has been a partner of Waterwood Investment since December 2014, which is a private equity firm focusing on growth stage opportunities in healthcare, technology and new economy industries. He co-founded Gobi Ventures in January 2002 and served as a senior managing partner until December 2014. From August 2000 to March 2001, he served as the chief financial officer at Asia2B.com. From September 1998 to March 2000, he worked at Wah Tak Management Limited with his last position held as an executive director. From April 1997 to March 1999, he served as the vice chairman and a director at Seapower Financial Services Group. Prior to that, Mr. Lau worked at law firms from September 1988 to May 1995, including as a partner at So & Keung and So Keung & Yip and as an attorney at Baker & McKenzie.<br /><br />
          
          Mr. Lau obtained a LL.B. degree from The University of Hong Kong in May 1985 and a postgraduate certificate in laws in May 1986. Mr. Lau is qualified to practice law in Hong Kong, California, Singapore, and England and Wales.`,
        },
      ],
    },
    {
      classify: "Independent Non-executive Directors",
      id: "IED",
      userInfo: [
        {
          name: "• Mr. ZHANG Min",
          id: "ZHANGMin",
          desc: `aged 53, is our independent non-executive Director. He is primarily responsible for supervising and providing independent opinion to our Board.<br /><br />

          Mr. Zhang has served as the general manager of Shanghai Empower Investment Co., Ltd. (上海合之力投资管理有限公司) since September 2012. Prior to that, Mr. Zhang successively served as a business development director at Morningstar Information Technology Consulting (Shanghai) Co., Ltd. (晨兴资讯科技谘询(上海)有限公司) from December 2005 to October 2008, as a vice president at Media Partners International Limited (上海梅迪派勒广告有限公司) from December 2002 to December 2005, and as a senior manager in risk control department at Shanghai branch of PricewaterhouseCoopers Consultant(Shenzhen) Co., Ltd. from March 2001 to November 2002. He has served as an independent director of Greenland Technologies Holding Corporation, a company listed on NASDAQ (symbol: GTEC), from October 2019 to December 2020. He has also served as an independent non-executive director of Zero2IPO Holdings since December 2020.<br /><br />
          
          Mr. Zhang obtained a bachelor’s degree in economics from Sichuan University (四川大学) in July 1989 and a master’s degree in international business from The Norwegian School of Economics and Business Administration in the spring term of 1995.`,
        },
        {
          name: "• Mr. XUE Linnan (薛林楠)",
          id: "XUELinnan",
          desc: `aged 49, is our independent non-executive Director. He is primarily responsible for supervising and providing independent opinion to our Board.<br /><br />

          He has served as the chief financial officer at Deepwise Co., Ltd. (深睿高科技有限公司) since April 2021, where he is primarily responsible for the overall financing management and risk control. From April 2013 to December 2020, Mr. Xue served consecutively as the general manager of audit department and customer service and product quality supervision department as well as the chief financial officer of Fosun International Limited (复星国际有限公司), a company listed on Stock Exchange (stock code: 0656), and as the vice chairman of Fosun Hive (复星蜂巢) where he was primarily responsible for the internal audit, financing due diligence, financing management and real estate investment and operation.<br /><br />
          
          Mr. Xue graduated with major of international taxation from Renmin University of China (中国人民大学) in September 1997 and has obtained a master’s degree in economics from the Boston University in September 2001. He has been a member of American Institute of Certified Public Accountants since February 2002 and a member of American Institute of Internal Control since November 2006.`,
        },
        {
          name: "• Dr. LI Weifeng (李卫锋)",
          id: "LIWeifeng",
          desc: `aged 44, is our independent non-executive Director. He is primarily responsible for supervising and providing independent opinion to our Board.<br /><br />

          Dr. Li has served in various positions at The University of Hong Kong since July 2011, where he successively served as an assistant professor and an associate professor in as well as the deputy head of the department of urban planning and design of The University of Hong Kong, and he has been the associate dean of the faculty of architecture of The University of Hong Kong since September 2021.<br /><br />
          
          Dr. Li obtained his dual bachelor’s degree in geography and economics from Peking University (北京大学) in June 2001. He also obtained a master’s degree in geography from Peking University in June 2004 and a Ph.D. in urban and regional planning from Massachusetts Institute of Technology in February 2015.`,
        },
      ],
    },
  ],
  SMT: {
    classify: "Senior Management Team",
    id: "SMT",
    userInfo: [
      {
        name: "NI Zhengdong",
        zh_name: "倪正东",
        sex: "Mr.",
        id: "Zhengdong",
        desc: `chairman of the Board,<br /> executive Director and co-chief executive officer. `,
      },
      {
        name: "YE Qing",
        zh_name: "叶青",
        sex: "Mr.",
        id: "YEQing",
        desc: `executive Director and co-chief executive officer.`,
      },
      {
        name: "LI Zhu",
        zh_name: "李竹",
        sex: "Mr.",
        id: "LIZhu",
        desc: `executive Director and co-chief operation officer.`,
      },
      {
        name: "CHEN Yaochao",
        zh_name: "陈耀超",
        sex: "Mr.",
        id: "CHENYaochao",
        desc: `executive Director and co-chief operation officer. `,
      },
    ],
  },
  BC: {
    classify: "Board Committees",
    id: "BC",
    userInfo: [
      {
        name: "• Audit Committee",
        id: "AC",
        desc: `Mr. XUE Linnan (Chairman)、Mr. ZHANG Min、Dr. LI Weifeng`,
      },
      {
        name: "• Remuneration Committee",
        id: "RC",
        desc: `Dr. LI Weifeng (Chairman)、Mr. LI Zhu、Mr. ZHANG Min`,
      },
      {
        name: "• Nomination Committee",
        id: "NC",
        desc: `Mr. NI Zhengdong (Chairman)、Dr. LI Weifeng、Mr. ZHANG Min`,
      },
    ],
  },
};
