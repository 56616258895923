<template>
  <div class="footer">
    <b-row
      no-gutters
      align-h="center"
      cols="1"
      cols-sm="1"
      cols-md="1"
      cols-lg="2"
      cols-xl="2"
      class="footer-con"
    >
      <b-col>
        <div class="footer-item">
          <span><img src="../assets/footer/icon_address@3x.png" alt="" /></span>
          <span>{{ $t("home.footer.address") }}</span>
        </div>
      </b-col>
      <b-col>
        <div class="footer-item">
          <div><img src="../assets/footer/icon_phone@3x.png" alt="" /></div>
          <span> {{ $t("home.footer.phone") }}</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      langName: "中",
      langList: [
        {
          name: "中",
          value: "zh",
        },
        {
          name: "EN",
          value: "en",
        },
      ],

      routerList: [],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
  margin: 0 auto;
  padding: 30px 1em;
  background: #fafafa;
  .footer-con {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    .footer-item {
      display: flex;
      text-align: left;
      line-height: 1.8;
      margin: 10px 1em;
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        margin-top: -3px;
      }
    }
  }
}
</style>
