export default {
  navList: [
    { name: "概览", id: "", path: "/" },
    { name: "发起人", id: "", path: "/about" },
    { name: "战略", id: "", path: "" },
    { name: "De-SPAC标准", id: "", path: "" },
    { name: "公司治理", id: "", path: "" },
  ],
  footer: {
    address: "香港九龙柯士甸道西1号环球贸易广场15楼1506B室",
    phone: "+852 21878118",
  },
};
