import overview from "@/lang/moudle/overview/en";
import promoters from "@/lang/moudle/promoters/en";
import governance from "@/lang/moudle/governance/en";
import routers from "@/lang/moudle/router/en";
import strategy from "@/lang/moudle/strategy/en";
import home from "@/lang/moudle/home/en";
import despac from "@/lang/moudle/deSpac/en";
import investor from "@/lang/moudle/investor/en";

export default {
  websiteName: "清科國際",
  home,
  overview,
  promoters,
  governance,
  routers,
  strategy,
  despac,
  investor,
};
